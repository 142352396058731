function __$styleInject(css) {
    if (!css) return;

    if (typeof window == 'undefined') return;
    var style = document.createElement('style');
    style.setAttribute('media', 'screen');

    style.innerHTML = css;
    document.head.appendChild(style);
    return css;
}

import { __rest, __awaiter } from 'tslib';
import { jsxs, Fragment, jsx } from 'react/jsx-runtime';
import { forwardRef, useState, useRef, useCallback, useImperativeHandle, memo, useMemo } from 'react';
import { version } from 'antd';
import LocaleReceiver from 'antd/es/locale-provider/LocaleReceiver';
import AntModal from 'antd/es/modal';
import AntUpload from 'antd/es/upload';
import { compareVersions } from 'compare-versions';
import Cropper from 'react-easy-crop';
import AntSlider from 'antd/es/slider';

const PREFIX = 'img-crop';
const INIT_ZOOM = 1;
const ZOOM_STEP = 0.1;
const INIT_ROTATE = 0;
const ROTATE_STEP = 1;
const MIN_ROTATE = -180;
const MAX_ROTATE = 180;

const EasyCrop = forwardRef((props, ref) => {
    const { cropperRef, image, aspect, shape, grid, zoom, rotate, minZoom, maxZoom, cropperProps, } = props;
    const [crop, onCropChange] = useState({ x: 0, y: 0 });
    const [cropSize, setCropSize] = useState({ width: 0, height: 0 });
    const [zoomVal, setZoomVal] = useState(INIT_ZOOM);
    const [rotateVal, setRotateVal] = useState(INIT_ROTATE);
    const cropPixelsRef = useRef({ width: 0, height: 0, x: 0, y: 0 });
    const onMediaLoaded = useCallback((mediaSize) => {
        const { width, height } = mediaSize;
        const ratioWidth = height * aspect;
        if (width > ratioWidth) {
            setCropSize({ width: ratioWidth, height });
        }
        else {
            setCropSize({ width, height: width / aspect });
        }
    }, [aspect]);
    const onCropComplete = useCallback((_, croppedAreaPixels) => {
        cropPixelsRef.current = croppedAreaPixels;
    }, []);
    useImperativeHandle(ref, () => ({
        rotateVal,
        setZoomVal,
        setRotateVal,
        cropPixelsRef,
    }));
    return (jsxs(Fragment, { children: [jsx(Cropper, Object.assign({}, cropperProps, { ref: cropperRef, image: image, crop: crop, cropSize: cropSize, onCropChange: onCropChange, aspect: aspect, cropShape: shape, showGrid: grid, zoomWithScroll: zoom, zoom: zoomVal, rotation: rotateVal, onZoomChange: setZoomVal, onRotationChange: setRotateVal, minZoom: minZoom, maxZoom: maxZoom, onMediaLoaded: onMediaLoaded, onCropComplete: onCropComplete, classes: {
                    containerClassName: `${PREFIX}-container`,
                    mediaClassName: `${PREFIX}-media`,
                } })), zoom && (jsxs("section", Object.assign({ className: `${PREFIX}-control ${PREFIX}-control-zoom` }, { children: [jsx("button", Object.assign({ onClick: () => setZoomVal(zoomVal - ZOOM_STEP), disabled: zoomVal - ZOOM_STEP < minZoom }, { children: "\uFF0D" })), jsx(AntSlider, { min: minZoom, max: maxZoom, step: ZOOM_STEP, value: zoomVal, onChange: setZoomVal }), jsx("button", Object.assign({ onClick: () => setZoomVal(zoomVal + ZOOM_STEP), disabled: zoomVal + ZOOM_STEP > maxZoom }, { children: "\uFF0B" }))] }))), rotate && (jsxs("section", Object.assign({ className: `${PREFIX}-control ${PREFIX}-control-rotate` }, { children: [jsx("button", Object.assign({ onClick: () => setRotateVal(rotateVal - ROTATE_STEP), disabled: rotateVal === MIN_ROTATE }, { children: "\u21BA" })), jsx(AntSlider, { min: MIN_ROTATE, max: MAX_ROTATE, step: ROTATE_STEP, value: rotateVal, onChange: setRotateVal }), jsx("button", Object.assign({ onClick: () => setRotateVal(rotateVal + ROTATE_STEP), disabled: rotateVal === MAX_ROTATE }, { children: "\u21BB" }))] })))] }));
});
var EasyCrop$1 = memo(EasyCrop);

__$styleInject(".img-crop-modal .img-crop-container {\n  position: relative;\n  width: 100%;\n  height: 40vh;\n}\n.img-crop-modal .img-crop-control {\n  display: flex;\n  align-items: center;\n  width: 60%;\n  margin-left: auto;\n  margin-right: auto;\n}\n.img-crop-modal .img-crop-control:first-of-type {\n  margin-top: 16px;\n}\n.img-crop-modal .img-crop-control:last-of-type {\n  margin-bottom: -8px;\n}\n.img-crop-modal .img-crop-control button {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 34px;\n  height: 34px;\n  padding: 0;\n  font-style: normal;\n  background: transparent;\n  border: 0;\n  outline: 0;\n  cursor: pointer;\n}\n.img-crop-modal .img-crop-control button[disabled] {\n  cursor: default;\n}\n.img-crop-modal .img-crop-control button + div:only-of-type {\n  flex: 1;\n  margin: 0 8px;\n}\n.img-crop-modal .img-crop-control-zoom button {\n  font-size: 18px;\n}\n.img-crop-modal .img-crop-control-rotate button {\n  font-size: 16px;\n}\n.img-crop-modal .img-crop-control-rotate button:first-of-type {\n  transform: rotate(-20deg);\n}\n.img-crop-modal .img-crop-control-rotate button:last-of-type {\n  transform: rotate(20deg);\n}\n");

const modalVisibleProp = compareVersions(version, '4.23.0') === -1
    ? { visible: true }
    : { open: true };
const ImgCrop = forwardRef((props, cropperRef) => {
    const { aspect = 1, shape = 'rect', grid = false, quality = 0.4, fillColor = 'white', zoom = true, rotate = false, minZoom = 1, maxZoom = 3, modalTitle, modalWidth, modalOk, modalCancel, modalMaskTransitionName, modalTransitionName, modalClassName, onModalOk, onModalCancel, beforeCrop, onUploadFail, cropperProps, children, } = props;
    const cb = useRef({});
    cb.current.onModalOk = onModalOk;
    cb.current.onModalCancel = onModalCancel;
    cb.current.beforeCrop = beforeCrop;
    cb.current.onUploadFail = onUploadFail;
    /**
     * Upload
     */
    const [image, setImage] = useState('');
    const fileRef = useRef({});
    const beforeUploadRef = useRef();
    const resolveRef = useRef(() => { });
    const rejectRef = useRef(() => { });
    const uploadComponent = useMemo(() => {
        const upload = Array.isArray(children) ? children[0] : children;
        const _a = upload.props, { beforeUpload, accept } = _a, restUploadProps = __rest(_a, ["beforeUpload", "accept"]);
        beforeUploadRef.current = beforeUpload;
        return Object.assign(Object.assign({}, upload), { props: Object.assign(Object.assign({}, restUploadProps), { accept: accept || 'image/*', beforeUpload: (file, fileList) => {
                    return new Promise((resolve, reject) => __awaiter(void 0, void 0, void 0, function* () {
                        if (cb.current.beforeCrop) {
                            const shouldCrop = yield cb.current.beforeCrop(file, fileList);
                            if (!shouldCrop) {
                                return reject();
                            }
                        }
                        fileRef.current = file;
                        resolveRef.current = (newFile) => {
                            var _a, _b;
                            (_b = (_a = cb.current).onModalOk) === null || _b === void 0 ? void 0 : _b.call(_a, newFile);
                            resolve(newFile);
                        };
                        rejectRef.current = (uploadErr) => {
                            var _a, _b;
                            (_b = (_a = cb.current).onUploadFail) === null || _b === void 0 ? void 0 : _b.call(_a, uploadErr);
                            reject();
                        };
                        const reader = new FileReader();
                        reader.addEventListener('load', () => {
                            if (typeof reader.result === 'string') {
                                setImage(reader.result);
                            }
                        });
                        reader.readAsDataURL(file);
                    }));
                } }) });
    }, [children]);
    /**
     * Crop
     */
    const easyCropRef = useRef({});
    /**
     * Modal
     */
    const modalProps = useMemo(() => {
        const obj = {
            width: modalWidth,
            okText: modalOk,
            cancelText: modalCancel,
            maskTransitionName: modalMaskTransitionName,
            transitionName: modalTransitionName,
        };
        Object.keys(obj).forEach((prop) => {
            const key = prop;
            if (obj[key] === undefined) {
                delete obj[key];
            }
        });
        return obj;
    }, [
        modalCancel,
        modalMaskTransitionName,
        modalOk,
        modalTransitionName,
        modalWidth,
    ]);
    const onClose = () => {
        setImage('');
        easyCropRef.current.setZoomVal(INIT_ZOOM);
        easyCropRef.current.setRotateVal(INIT_ROTATE);
    };
    const onCancel = useCallback(() => {
        var _a, _b;
        (_b = (_a = cb.current).onModalCancel) === null || _b === void 0 ? void 0 : _b.call(_a);
        onClose();
    }, []);
    const onOk = useCallback((event) => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        onClose();
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        const target = event.target;
        const context = ((_a = target === null || target === void 0 ? void 0 : target.getRootNode) === null || _a === void 0 ? void 0 : _a.call(target)) || document;
        const imgSource = context.querySelector(`.${PREFIX}-media`);
        const { width: cropWidth, height: cropHeight, x: cropX, y: cropY, } = easyCropRef.current.cropPixelsRef.current;
        if (rotate && easyCropRef.current.rotateVal !== INIT_ROTATE) {
            const { naturalWidth: imgWidth, naturalHeight: imgHeight } = imgSource;
            const angle = easyCropRef.current.rotateVal * (Math.PI / 180);
            // get container for rotated image
            const sine = Math.abs(Math.sin(angle));
            const cosine = Math.abs(Math.cos(angle));
            const squareWidth = imgWidth * cosine + imgHeight * sine;
            const squareHeight = imgHeight * cosine + imgWidth * sine;
            canvas.width = squareWidth;
            canvas.height = squareHeight;
            ctx.fillStyle = fillColor;
            ctx.fillRect(0, 0, squareWidth, squareHeight);
            // rotate container
            const squareHalfWidth = squareWidth / 2;
            const squareHalfHeight = squareHeight / 2;
            ctx.translate(squareHalfWidth, squareHalfHeight);
            ctx.rotate(angle);
            ctx.translate(-squareHalfWidth, -squareHalfHeight);
            // draw rotated image
            const imgX = (squareWidth - imgWidth) / 2;
            const imgY = (squareHeight - imgHeight) / 2;
            ctx.drawImage(imgSource, 0, 0, imgWidth, imgHeight, imgX, imgY, imgWidth, imgHeight);
            // crop rotated image
            const imgData = ctx.getImageData(0, 0, squareWidth, squareHeight);
            canvas.width = cropWidth;
            canvas.height = cropHeight;
            ctx.putImageData(imgData, -cropX, -cropY);
        }
        else {
            canvas.width = cropWidth;
            canvas.height = cropHeight;
            ctx.fillStyle = fillColor;
            ctx.fillRect(0, 0, cropWidth, cropHeight);
            ctx.drawImage(imgSource, cropX, cropY, cropWidth, cropHeight, 0, 0, cropWidth, cropHeight);
        }
        // get the new image
        const { type, name, uid } = fileRef.current;
        canvas.toBlob((blob) => __awaiter(void 0, void 0, void 0, function* () {
            const newFile = Object.assign(new File([blob], name, { type }), { uid });
            if (!beforeUploadRef.current) {
                return resolveRef.current(newFile);
            }
            const rcFile = newFile;
            const result = yield beforeUploadRef.current(rcFile, [rcFile]);
            if (result === true) {
                return resolveRef.current(newFile);
            }
            if (result === false) {
                return rejectRef.current(new Error('beforeUpload return false'));
            }
            delete newFile[AntUpload.LIST_IGNORE];
            if (result === AntUpload.LIST_IGNORE) {
                Object.defineProperty(newFile, AntUpload.LIST_IGNORE, {
                    value: true,
                    configurable: true,
                });
                return rejectRef.current(new Error('beforeUpload return LIST_IGNORE'));
            }
            if (typeof result === 'object' && result !== null) {
                return resolveRef.current(result);
            }
        }), type, quality);
    }), [fillColor, quality, rotate]);
    const getComponent = (titleOfModal) => (jsxs(Fragment, { children: [uploadComponent, image && (jsx(AntModal, Object.assign({}, modalVisibleProp, { wrapClassName: `${PREFIX}-modal ${modalClassName || ''}`, title: titleOfModal, onOk: onOk, onCancel: onCancel, maskClosable: false, destroyOnClose: true }, modalProps, { children: jsx(EasyCrop$1, { ref: easyCropRef, cropperRef: cropperRef, image: image, aspect: aspect, shape: shape, grid: grid, zoom: zoom, rotate: rotate, minZoom: minZoom, maxZoom: maxZoom, cropperProps: cropperProps }) })))] }));
    if (modalTitle) {
        return getComponent(modalTitle);
    }
    return (jsx(LocaleReceiver, { children: (_, code) => {
            return getComponent(code === 'zh-cn' ? '编辑图片' : 'Edit image');
        } }));
});

export { ImgCrop as default };
